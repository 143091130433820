import React from "react"

const Section = ( {children} ) => {
    
        return (
            <section className={`page-wrap `}>
                {children}
            </section>
        )
}


export default Section;
