import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"

class TermsNav extends React.Component {

    render() {

        return (
                <StaticQuery
                    query={graphql`
                        query {
                            wpMenu(name: {eq: "Terms"}) {
                                id
                                name
                                slug
                                menuItems {
                                    nodes {
                                        databaseId
                                        cssClasses
                                        path
                                        url
                                        label
                                    }
                                } 
                            }
                        }
                    `}
                    render = {data => (
                        <nav className={'pull-right nav ' + this.props.menu}>
                            <TermsMenu menu={data} />
                        </nav>
                    )}
                />
        );
    }
}

class TermsMenu extends React.Component {

    render() {
        
        let menu = this.props.menu.wpMenu;

        if( ! menu ) return null;

        let menuItems = menu.menuItems.nodes.map((item, i) => {

            let label = item.label;
    
            if(menu.slug === 'terms') {
                let re = /(&#038;\s(.*))$/g;
                let match = label.match( re );
                if ( match ) {
                    label = label.replace(re, "");
                    item.label = label + ` <span className="subitem">${match[0]}</span>`;
                } 
            }
    
            return <Item key={i} item={item}/>;
        } );

        return (
            <ul className="menu">
                {menuItems}            
            </ul>
        )
    }

}


const Item = ({ item }) => (
    <li><Link to={item.url} dangerouslySetInnerHTML={{__html: item.label }}></Link></li>
)


export default TermsNav

