import { Link } from "gatsby"
import React from "react"

import FooterNav from "./nav/footer-nav"
import TermsNav from "./nav/terms-nav"

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
        <div className="row">
            <div className="col-12 col-sm-8">
                <FooterNav menu="footer"/>
            </div>
            <div className="col-12 col-sm-4">
                <TermsNav menu="footer"/>
            </div>
        </div>
    </div>
  </footer>
)


export default Footer
