import { useStaticQuery, graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// import TopNav from "./nav/top-nav.js";

const Header = ({ Logo, wrapStyle }) => {

  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `);
  
  return (

    <header className="site-header">
      <div className={wrapStyle === 'fluid' ? "container-fluid " : "container " }>
          <div className="row no-gutters">
              <div className="col">
                <div className="site-title">
                    <Link to="/" title={data.site.siteMetadata.title}>
                      <Logo />
                    </Link>
                </div>
              </div>
              {/* <div className="col">
                <TopNav menu="main"/>
              </div> */}
          </div>
      </div>
    </header>
  )

}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
