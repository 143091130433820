import React from "react";
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition =  React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
  });


class Wrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sent: false,
            width: 0
        }

        this.formSubmit = this.formSubmit.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    }


    componentWillMount(){
        if( typeof window !== 'undefined' )
            this.setState({width: window.innerWidth});
    }


    handleDialogClose = () => {
        this.setState({sent: false});
    };


    componentDidMount() {
        
        let forms = document.getElementsByClassName('at');

        for(let form of forms) {
            form.addEventListener('submit', this.formSubmit);
        }

        if( typeof window !== 'undefined' )
            window.addEventListener('resize', this.handleWindowSizeChange)
    }

    handleWindowSizeChange() {
        if( typeof window !== 'undefined' )
            this.setState({width: window.innerWidth});
    }
    

    formSubmit(e) {

        e.preventDefault();
        e.stopPropagation();

        this.setState({sent: 'sending'});

        const form = e.target;
        const submit = form.querySelectorAll('button[type="submit"]')[0];

        let adminUrl = new URL( process.env.API_ADDRESS || `https://admin.asana.travel` );
        let data = new FormData( form );

        data.append('at_contact', 1);

        form.style.opacity = '0.5';
        form.style.cursor = 'wait';
        submit.disabled = true;
        
        fetch(adminUrl.origin, {
            method: 'POST',
            // headers: {
            //     'Accept': 'application/json',
            //     'Content-Type': 'application/json',
            // },
            mode: 'no-cors',
            headers: {
                "Access-Control-Allow-Origin": '*'
            },
            body: data
        })
        .then((res) => {
            form.style.opacity = '1';
            form.style.cursor = 'default';
            submit.disabled = false;

            this.setState({sent: 'sent'});

            
        })
        .catch(rejected => {
            console.log(rejected);
        });
    }


    render() {

        let title = this.state.sent === 'sent' ? "Your message has been sent" : `Sending your message...`;
        let open = this.state.sent !== false;
        let maxWidth = this.state.width > 991 ? '50%' : '100%';

      
        return (
            <section className={`page-wrapper `}>
                {this.props.children}
                <Dialog
                    open={open}
                    fullScreen={false}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    style={{maxWidth: maxWidth}}
                >
                    {this.state.sent === 'sent' ?
                    (
                        <div>
                        <DialogTitle id="alert-dialog-slide-title" disableTypography={true} style={{minWidth: '350px', textAlign: 'center'}}> <div style={{ padding: '5px'}}>{title}</div> </DialogTitle>
                        <DialogActions>
                            <Button 
                                onClick={this.handleDialogClose} 
                                style={{
                                    'backgroundColor': '#86dabd',
                                    'color': '#fff',
                                    'fontSize': '1.5rem',
                                    'fontFamily': 'inherit',
                                    'alignItems': 'flex-start',
                                    margin: '5px auto'
                                }}
                            >
                                OK
                            </Button>
                        </DialogActions></div>) 
                    : 
                    (<DialogTitle id="alert-dialog-slide-title" disableTypography={true} style={{minWidth: '350px', textAlign: 'center'}}><div style={{padding: '5px'}}>{title}</div> <CircularProgress size='3rem' style={{display: 'block', margin: '25px auto 0 auto', color: '#86dabd', 'verticalAlign': 'middle'}} />  </DialogTitle>)

                    }
                    
                </Dialog>
            </section>
        )
    }
}

export default Wrapper;
